import React from 'react'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'

import { graphql, Link } from 'gatsby'
import Layout from 'src/components/layout'
import Row from 'src/components/row'

const Page = ({
  data: {
    site,
    imgCommercialLogos,
    imgDemolition,
    imgFoundation,
    imgStructural,
    imgInterior,
  },
}) => (
  <Layout>
    <div className="content">
      <Helmet>
        <title>
          Commercial Construction, Renovation &amp; Maintenance -{' '}
          {site.siteMetadata.title}
        </title>
      </Helmet>
      <h2>Some of our Clients</h2>
      <Img
        className="center"
        style={{
          maxWidth: imgCommercialLogos.childImageSharp.original.width,
        }}
        fluid={imgCommercialLogos.childImageSharp.fluid}
      />

      <hr />

      <h2>Commercial Services</h2>
      <Row columns={2}>
        <div>
          <p>
            Since 1974, ARC has completed hundreds of commercial projects
            including new buildings, renovations, maintenance, or upgrades. This
            includes renovating high-volume food service outlets while ensuring
            that the store or restaurant can continue operating while being
            renovated. Our work includes stand-alone buildings, inline units in
            malls and power centres, and kiosks.
          </p>
          <p>
            Our full-time staff and work crews are long-time employees. Each
            possesses a refined and specialized skill set, built up over 43
            years of successful projects, particularly in the unique field of
            restaurant renovation and remodelling.
          </p>
        </div>
        <div>
          <p>
            We are experienced with and comfortable in various online bidding
            and project management software, and are ready to work with you to
            ensure quick, easy bidding and a well managed project. To learn more
            about our capabilities, read on.
          </p>
          <Link to="/commercial-projects">View more examples</Link>
        </div>
      </Row>

      <hr />
      <Row columns="2">
        <div>
          <h2>Demolition</h2>
          <Img
            style={{
              maxWidth: 300,
            }}
            fluid={imgDemolition.childImageSharp.fluid}
          />
          <p>
            Often, projects start with demolishing existing buildings or other
            structures. Every effort is made to recycle metals and other
            resources.
          </p>
        </div>
        <div>
          <h2>Footing &amp; Foundation</h2>
          <p>
            <Img
              style={{
                maxWidth: 300,
              }}
              fluid={imgFoundation.childImageSharp.fluid}
            />
          </p>
          <p>
            Our services include: Excavation, hauling, forming, reinforcing,
            preparation and participation in buildling inspections, soil
            engineering, placing anchors, insulating foundation walls,
            backfilling in lifts, and compacting a site underneath the
            supervision of a soil engineer,
          </p>
        </div>
      </Row>
      <Row columns="2">
        <div>
          <h2>Structural</h2>
          <p>
            <Img
              style={{
                maxWidth: 300,
              }}
              fluid={imgStructural.childImageSharp.fluid}
            />
          </p>
          <p>
            We have completed numerous projects consisting of steel and/or wood
            framed single-story buildings. We also have extensive experience
            with alterations to existing buildings or reworking components of a
            unit in a mall.
          </p>
        </div>
        <div>
          <h2>Interior &amp; Exterior</h2>
          <p>
            <Img
              style={{
                maxWidth: 300,
              }}
              fluid={imgInterior.childImageSharp.fluid}
            />
          </p>
          <p>
            We employ fine tradesmen who are capable of performing on time, with
            excellent results. Skills include stucco, bricks, architectural
            blocks, custom stucco mouldings, parking lots curbs, paving, site
            preparation, lighting, electrical, duct and conduits, drive thru
            loop installation, landscaping and irrigation.
          </p>
        </div>
      </Row>
      <hr />
      <Row columns="2">
        <ul>
          <li>
            We have the capacity to take your project from start to finish.
          </li>
          <li>We manage every project in-house.</li>
          <li>
            By offering a full-range of services and Project Management we are
            able to meet construction timelines, ensuring your projects are
            completed in a timely matter with quality workmanship you can trust.
          </li>
        </ul>
        <ul>
          <li>
            We take pride in our work, and employ highly skilled and qualified
            crew members and sub-trades.
          </li>
          <li>
            We are licensed, bonded and insured. We stand behind and warranty
            our work.
          </li>
        </ul>
      </Row>
    </div>
  </Layout>
)

export default Page

export const query = graphql`
  fragment Thumb on File {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
      original {
        width
      }
    }
  }

  query CommercialQuery {
    site {
      siteMetadata {
        title
      }
    }
    imgCommercialLogos: file(
      relativePath: { regex: "/commercial-logos.jpg/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
        original {
          width
        }
      }
    }
    imgDemolition: file(relativePath: { regex: "/commercial/demo/" }) {
      ...Thumb
    }
    imgFoundation: file(
      relativePath: { regex: "/commercial/footing-foundation/" }
    ) {
      ...Thumb
    }
    imgStructural: file(relativePath: { regex: "/commercial/structural/" }) {
      ...Thumb
    }
    imgInterior: file(
      relativePath: { regex: "/commercial/interior-exterior/" }
    ) {
      ...Thumb
    }
  }
`
